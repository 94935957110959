.search-bar {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 60%;
    background: white;
    z-index: 1001;
    transition: top 0.5s ease-in-out;
    box-sizing: border-box; 
    padding-top: 50px; 
  }
  
  .search-bar.open {
    top: 0;
  }
  
  .search-bar-header {
    display: flex;
    justify-content: flex-end;
  }
  
  .close-icon {
    position: absolute;
    top: 30px;
    right: 40px;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .search-bar-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;


  }

  .search-input-wrapper {
    position: relative;
    width: 40rem;
    margin-bottom: 20px;

  }
  
  .search-input-wrapper .search-icon {
    position: absolute;
    top: 50%;
    left: 26px;
    transform: translateY(-50%);
    font-size: 1.2rem;
  }

  
  .search-input-wrapper input {
    width: 100%;
    height: 30px;
    padding: 10px 40px 10px 65px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 50px;
  }

  .search-input-wrapper input:focus {
    border-color: black; 
    outline: none
  }
  
  .search-content {
    margin-top: 40px;
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 100%; 
    border-top: 0.5px solid #CDCDCD;

  }
  
  .search-column-left,
  .search-column-right {
    flex: 1; 
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    padding: 10px;
  }
  
  .search-column-left {
    display: grid;
    grid-template-columns: repeat(2, auto); /* Two columns with auto width */
    grid-gap: 50px; /* Reduce space between items */
    justify-items: center; 
    align-items: center; 
    text-align: left;
  }
  
  .search-column-left p {
    margin: 0;
    cursor: pointer;
    text-align: center;
    font-weight: 300;
  }

  .search-column-left .women {
    margin: 0;
    padding-left: 120px;
    cursor: pointer;
    text-align: center;
    font-weight: 300;
  }

  .search-column-left p:hover {
    color: rgb(96, 96, 96);
  }
  .search-divider {
    width: 0.8px;
    background-color: #CDCDCD;
    height: 260px
  }


  .search-column-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    justify-content: center; 
  }

  
  .search-column-right h3 {
    margin-left: 80px;
    margin-bottom: 30px;
    font-size: 0.7rem;
    font-weight: 400;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
    grid-gap: 80px;
  }
  
  .product-item img {
    margin-left: 80px;
    width: 60%; 
    height: auto; 
  }
  
  .product-item p {
    margin-left: 80px;
    margin-top: 15px;
    font-weight: 300;
    text-align: left; 
    font-size: 0.8rem;
    cursor: pointer;
  }

.product-item p:hover {
  color: rgb(96, 96, 96);
}

.input-clear-icon {
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.input-clear-icon:hover {
  opacity: 1;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

input {
  width: 100%;
  padding: 10px 40px 10px 35px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


  