.custom-select {
    position: relative;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .custom-select label {
    color: rgb(89, 89, 89)

  }
  
  .custom-select__trigger {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    color: gray;
  }
  
  .custom-select__trigger.open {
    background-color: #efefef;
  }
  
  .custom-select__options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(220, 220, 220, 0.1);
    z-index: 1000;
    color: rgb(84, 84, 84);
    font-weight: 300;
    font-size: 14px;
  }
  
  .custom-select__option {
    padding: 10px;
    cursor: pointer;
  }
  
  .custom-select__option:hover {
    background-color: #e8e8e8;
  }
  
  .arrow {
    margin-left: 10px;
    font-size: 12px;
  }
  