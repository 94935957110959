.about-lelord {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 100px;
}

.about-lelord p {
    font-size: 14px;
    line-height: 25px;
    color: rgb(81, 81, 81);
    font-weight: 300;
}

.about-lelord img {
    width: 50%;
    object-fit: cover;

}

.about-lelord div {
    width: 50%;
    padding-left: 20px;
  }

@media (max-width: 768px) {
   .about-lelord {
    flex-direction: column;
    gap: 0px;
   }
  
  .about-lelord img, 
  .about-lelord div {
    width: 100%; 
  }

  .about-lelord div {
    padding-left: 0;
  }
}