.product-gallery {
    padding: 100px 0;
  }
  
  .product-header {
    position: fixed;
    top: 83px;
    left: 0;
    width: 100%;
    background: white;
    padding: 33px 40px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  
  .product-header .title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
  }
  
  .sort-bar {
    position: relative;
  }
  
  .sort-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    font-size: 14px;
    gap: 20px;
  }

  
  .arrow-icon {
    margin-left: 8px;
    transition: transform 0.3s ease;
  }
  
  .arrow-icon.down {
    transform: rotate(0deg);
  }
  
  .arrow-icon.up {
    transform: rotate(180deg);
  }
  
  .sort-modal {
    position: absolute;
    top: 100%;
    left: -40px;
    background: white;
    width: 150px;
    padding: 10px 0;
    z-index: 1000;
  }
  
  .sort-modal p {
    font-size: 13px;
    font-weight: 300;
    padding: 10px;
    margin: 0;
    cursor: pointer;
  }
  
  .sort-modal p:hover {
    color: #4f4f4f;
  }
  
  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1px;
    row-gap: 30px;
    margin-top: 50px;
  }
  
  .gallery div {
    text-align: center;
    padding: 1px;
    box-sizing: border-box;
  }
  
  .gallery img {
    width: 100%;
    height: 470px;
    object-fit: cover;     
    object-position: center;
  }
  
  .gallery p {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    .product-header {
      top: 46px;
      padding: 20px 15px;

    }

    .gallery div {
      height: 350px;
    }
    
    .gallery {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      margin-top: 20px;
    }

    .gallery img {
      height: 100%;
      
    }

    .gallery p {
      margin-top: 14px;
      font-size: 12px;
    }
  
  }
  