/* Container that holds the whole loading animation */
.fashion-loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f8f8;
  }
  
  /* Dots animation */
  .dots {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #c0c0c0;
    animation: dotAnimation 1.5s infinite ease-in-out;
  }
  
  /* Individual dot delays */
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  /* Animation for the dots */
  @keyframes dotAnimation {
    0%, 100% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.5);
      opacity: 1;
    }
  }
  
  /* Fashion icon and model silhouette */
  .fashion-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    animation: revealFashionIcon 3s forwards;
  }
  
  @keyframes revealFashionIcon {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Model silhouette */
  .model-silhouette {
    position: relative;
    width: 60px;
    height: 120px;
    background-color: transparent;
    border: 2px solid #333;
    border-radius: 50px 50px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: revealModel 3s ease-in-out 1.5s forwards;
  }
  
  .model-head {
    width: 20px;
    height: 20px;
    background-color: #333;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .model-body {
    width: 30px;
    height: 50px;
    background-color: #333;
    border-radius: 30px 30px 0 0;
  }
  
  /* Loading text */
  .loading-text {
    margin-top: 20px;
    font-family: 'Arial', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    animation: fadeInText 2s ease-in-out 2.5s forwards;
  }
  
  @keyframes fadeInText {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  