.repair-container {
    display: flex;
    justify-content: space-between;
    font-family: 'Inter', sans-serif;
  }
  
  .repair-form-container, .description-container {
    width: 45%;
  }
  
  h2 {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 300;
    color: rgb(89, 89, 89)
  }
  
  .repair-form-container input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(65, 65, 65);

  }
  
  textarea {
    height: 100px;
    resize: vertical;

  }
  
  textarea:focus {
    outline: 1px solid black;

  }

  .file-upload {
    position: relative;
  }
  
  .file-upload input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  input:focus {
    outline: none;
    border: 1px solid black;
  }
  
  .file-upload::before {
    content: 'Upload Photo';
    display: inline-block;
    width: 96%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
    background-color: #f9f9f9;
    color: #333;
    cursor: pointer;
    z-index: 0;
  }
  
  .file-upload:hover::before {
    outline: 1px solid black;
  }
  
  .uploaded-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .uploaded-image {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .uploaded-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .repair-form-container .close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white; 
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    background-color: black;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    width: 50%;
    margin-top: 20px;
  }
  
  .form-submit-btn:hover {
    background-color: #333;
  }
  
  .description-container p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
  }

  .success-toast {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    animation: fade-in-out 3s ease forwards;
  }
  
  @keyframes fade-in-out {
    0% { opacity: 0; transform: translateY(10px); }
    10%, 90% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(10px); }
  }

  @media (max-width: 768px) {
    .repair-container {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
    .repair-form-container, .description-container {
      width: 100%;
    }
      
  .file-upload::before {
  
    width: 94%;
   
  }
  }
  