/* Sidebar.css */

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 400px;
    background-color: #ffffff;
    /* box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1); */
    z-index: 1005;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    text-transform: uppercase;
    color: rgb(131, 131, 131)
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .sidebar-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
  }
  
  .close-icon {
    top: 30px;
    right: 30px;
    cursor: pointer;
    height: auto;
    transition: filter 0.3s ease; 
  }

  .close-icon:hover {
    filter: contrast(20%); 
  }
  
  .sidebar-menu {
    list-style: none;
    padding: 30px 70px;
  }
  
  .menu-item {
    font-size: 1rem;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative; 
    transition: color 0.3s ease-in-out; 
  }
  
  .menu-item:hover {
    color: #404040;
  }
  
  .menu-item:not(.with-submenu)::after {
    content: '';
    position: absolute;
    bottom: -2px; 
    left: 0;
    width: 0%;
    height: 0.5px;
    background-color: #555555;
    transition: width 0.3s ease; 
  }
  

  .sidebar li:first-child:hover::after {
    width: 19%; 
  }

  .sidebar li:nth-child(3):hover::after {
    width: 24%; 
  }

  .submenu {
    list-style: none;
    margin-left: 15px;
    padding-left: 0;
  }
  
  .submenu .men-submenu-item {
    font-size: 1rem;
    margin-top: 25px;
    margin-left: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease-in-out;
  }
  
  .submenu .men-submenu-item:hover {
    color: #4e4e4e; 
  }
  
  .submenu .men-submenu-item::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0%;
    height: 0.5px;
    background-color: #4b4b4b; 
    transition: width 0.3s ease; 
  }
  
  .submenu .men-submenu-item:first-child:hover::after {
    width: 25%; 
  }

  .submenu .men-submenu-item:nth-child(2):hover::after {
    width: 25%; 
  }

  .submenu .men-submenu-item:nth-child(3):hover::after {
    width: 38%; 
  }

  
  @media (max-width: 768px) {
    .sidebar {
      width: 200px;
    }

      
  .menu-item {
    font-size: 0.8rem;
  }

  .sidebar-menu {
    padding: 30px;
  }


  .submenu .men-submenu-item {
    font-size: 0.8rem;
   
  }
  

}
  