
.submenu-sidebar {
    position: fixed;
    top: 0;
    right: -400px;
    height: 100%;
    width: 400px;
    background-color: #ffffff;
    border-left: 0.5px solid rgb(230, 230, 230);
    transition: right 0.3s ease-in-out;
    z-index: 1001; 
    display: none;
}

.submenu-sidebar.open {
    display: block; 
}

.submenu-sidebar-content {
    padding: 130px 50px;
    
}

.submenu-row {
    width: 100%;
    display: flex;
}


.submenu-column {
    flex: 1;
    padding: 10px 20px;
}

.submenu-column img {
    width: 100%;
    height: auto;
}

.submenu-list {
    list-style: none;
    padding: 20px;
    margin: 0;
    font-size: 0.9rem;
}

.submenu-list .nested-menu {
    cursor: pointer;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 2.5px;
}

.submenu-list .nested-menu:hover {
    color: #333333; 
}

.submenu-list .nested-menu::after {
    content: '';
    position: absolute;
    bottom: 0; 
    left: 0;
    width: 0; 
    height: 0.5px;
    background-color: #4b4b4b; 
    transition: width 0.3s ease; 
}


.submenu-list .nested-menu:first-child:hover::after {
    width: 100%; 
  }

.submenu-list .nested-menu:nth-child(2):hover::after {
    width: 75%; 
}
.submenu-list .nested-menu:nth-child(3):hover::after {
    width: 80%; 
}

@media (max-width: 768px) {
    .submenu-sidebar {
        right: -190px;
        width: 200px;
    }

    .submenu-column img {
        width: 50px;
        height: 50px;
    }

    .submenu-column:nth-of-type(2) {
        display: none;
    }
}




