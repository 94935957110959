/* LatestCollection.css */

.latest-section {
    text-align: center;
    padding: 80px 0;
  }
  
  .section-header {
    margin-bottom: 40px;
  }
  
  .section-header h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .section-header p {
    font-size: 0.8rem;
    color: #888;
    text-decoration: underline;
    font-weight: 300;
    cursor: pointer;
  }

  .section-header p:hover {
    color: rgb(62, 62, 62);
  }
  
  .image-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px; /* Left and right padding */
    gap: 5px; /* Small gap between images */
    overflow: hidden; /* Prevent horizontal scrolling */
  }
  
  .image-container {
    position: relative;
    flex: 1;
    max-width: calc(33.33% - 5px); /* Adjusted width to fit three images in a row with gap */
    max-height: 550px; /* Fixed height */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure image fits within the container */
  }
  
  .image-caption {
    margin-top: 30px; /* Adjusted margin top for caption */
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    .image-caption {
      margin-top: 10px;
      font-size: 0.7rem;
    }
  
  }
  