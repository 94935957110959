/* Footer.css */

.footer-section {
    /* background-color: #f8f8f8; */
    padding: 40px 0px;
    text-align: center;
  }
  
  .footer-line {
    height: 0.8px;
    background-color: #D9D9D9;
    width: 100%;
    margin: 60px auto 60px;
  }
  
  .footer-content {
    display: flex;
    align-items: top;
    justify-content: space-around;
    text-align: left;
    max-width: 100vw;
    padding: 0 150px;
  }
  
  .footer-column {
    flex: 1;
    margin: 0 10px;
  }
  
  .footer-column h3 {
    font-size: 0.8rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column li {
    margin-bottom: 16px;
    font-size: 0.8rem;
    font-weight: 300;
    color: rgb(102, 102, 102);
    cursor: pointer;
  }

  .footer-column li:hover {
    color: rgb(95, 95, 95);

  }

  .social-media {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .footer-bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 150px;
  }
  
  .footer-left {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 300;
  }
  
 
  .footer-right ul {
    list-style: none;
    display: flex;
    gap: 15px;
    padding: 0;
  }
  
  .footer-right li {
    font-size: 0.8rem;
    font-weight: 300;
  }

  
  .footer-center {
    font-size: 2.2rem;
    font-family: boldeFont;
    padding:0;
  }

  @media (max-width: 768px) {
    .footer-section {
      padding: 10px 0px;
    }
    .footer-content {
      flex-wrap: wrap;
      gap: 10px;
      padding: 0 50px;
    }
  
    .footer-bottom-row {
      flex-wrap: wrap;
      padding: 0px 15px;
    }

    .footer-left {
      font-size: 0.7rem;
    }

    .footer-right li {
      font-size: 0.7rem;
      font-weight: 300;
    }
  
    .footer-center {
      font-size: 2rem;
    }
  }