
.videoBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures video covers the container without stretching */
    z-index: -1; /* Sends the video behind the content */
    display: block;
  }
  .imagePlaceholder {

    z-index: 100; /* Sends the video behind the content */
    width:100vw;
    height:100vh;
  }


  .show{
visibility: visible;

  }

  .hide{
    visibility:hidden;
  }