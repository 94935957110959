.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align all testimonials */
    padding: 20px;
    /* font-family: 'Inter'; */
}

.card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
    border-radius: 10px;
    max-width: 800px;
    width: 90%; /* Slightly smaller width to create left-right effect */
    transition: transform 0.3s ease;
}

.card-left {
    margin-right: auto; /* Align to the left with leftover space on the right */
    transform: translateX(-10px); /* Slightly shift to the left */
}

.card-right {
    margin-left: auto; /* Align to the right with leftover space on the left */
    transform: translateX(10px); /* Slightly shift to the right */
}

.card-content {
    display: flex;
    align-items: center;
    width: 100%;
}

/* .image-container {
    flex-shrink: 0;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.circular-image {
    width: 140px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
}

.text-container {
    flex: 1;
}

.title {
    font-size: 14px;
    margin-bottom: 10px;
}

.description {
    margin-bottom: 10px;
    font-size: 14px;
    color: rgb(62, 62, 62);
}

.name {
    font-weight: bold;
    color: #7f7f7f;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.text-right {
    text-align: right;
}

@media (max-width: 768px) {

    .testimonial {
        padding: 0px;
    }

    .card-content {
      
        flex-direction: column;
        align-items: start; 
   }
    
    .card-left {
        margin-right: 0;
        transform: translateX(-0px); 
    }
    
    .card-right {
        margin-left: 0; 
        transform: translateX(0px);
    }

    .circular-image {
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 50%;
    }
    .order-1 {
        order: 1;
    }
    
    .order-2 {
        order: 1;
    }
    .text-right {
        text-align: left;
    }
 }
