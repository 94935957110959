/* ProductDetailPage.css */
.product-detail-page {
  display: flex;
  height: 100vh;
}

.left-section {
  flex: 1;
  overflow-y: auto;
  position: relative;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.left-section::-webkit-scrollbar {
  width: 0;
  background: transparent;
}


.carousel {
  display: flex;
  flex-direction: column;
}

.carousel .slide {
  position: relative;
  height: 100vh;
  width: 50vw;
  overflow: hidden;
}

.carousel .slide img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.right-section {
  display: flex;
  flex-direction: column;
  padding: 180px 180px;
  box-sizing: border-box;
  width: 50vw;
  gap: 40px;
  overflow-y: hidden;
}

.product-detail-header {
  display: flex;
  flex-direction: column;
  line-height: 1px;
}

.product-title {
  font-size: 20px;
  font-weight: 400;
}

.product-id {
  font-size: 12px;
}

.product-color-container {
  display: flex;
  width: 100%;
  gap: 200px;
  /* justify-content: space-between; */
  align-items: center;
}

.product-color {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}

.color-notation {
  display: flex;
  align-items: center;
}

.color-option {
  margin: 0 10px;
  padding: 8px 8px;
  border: none;
  border-radius: 50%;
}


.product-size {
  position: relative;
  margin-bottom: 20px;
}

.size-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.size-dropdown .arrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.size-dropdown .arrow.up {
  transform: rotate(180deg);
}

.size-modal {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 0;
  column-gap: 40px;
  row-gap: 20px;
  overflow: hidden;
  border-top: 0 solid transparent; /* Initialize border as transparent */
  transition: height 0.5s ease, border 0.5s ease, margin-top 0.5s ease; /* Smooth transitions */
  margin-top: 0; /* Initialize margin-top as 0 */
}

.size-modal.open {
  height: auto;
  overflow: visible;
  padding: 30px 20px; /* Content inside modal padding */
  border-top: 1px solid #7e7e7e; /* Border appears when modal is open */
  margin-top: 10px; /* Adjust margin-top to provide space when modal is open */
}

.size-modal p {
  font-size: 12px;
  color: rgb(136, 136, 136);
  cursor: pointer;
}

.size-modal p:hover {
  color: rgb(88, 88, 88)
}

.size-option {
  border: 1px solid black;
  border-radius: 50%;
  padding: 8px;
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex: 1 0 20%;  */
}

.size-chart-text {
  font-size: 13px;
  color: gray;
  cursor: pointer;
}

.size-chart-text:hover {
  color: rgb(71, 71, 71);
}

.product-button button {
  padding: 12px;
  background-color: #1e1e1e;
  border: none;
  border-radius: 30px;
  color: white;
  width: 80%;
}

.product-button button:hover {
  background-color: #2d2d2d;
}

.size-chart-modal {
  position: fixed;
  top: 100px;
  left: 100px;
  bottom: 0;
  width: 40%;
  height: 100%;
  background: white;
  z-index: 1000;
  /* border-right: 1px solid black; */
  display: flex;
  padding: 50px;
 
}

.size-chart-modal h5 {
  font-weight: 400;
  font-size: 16px;
}

.size-chart-content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.size-chart-close-button {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 20px;
  cursor: pointer;
}

.product-detail-modal {
  position: relative;
}

.product-detail-modal p {
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.product-detail-modal p:hover {
  text-decoration: underline;
}

.product-detail-modal-content {
  position: fixed;
  top: 0;
  right: 0;
  width: 47.5vw;
  height: 100vh;
  background: white;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.5s ease;
}

.product-detail-modal-content.open {
  transform: translateX(0);
}

.product-detail-modal-body {
  padding: 80px 100px;
  overflow-y: auto;
}

.product-detail-modal-body h4 {
  font-weight: 400;
  font-size: 16px;
  font-weight: 400;
}

.product-detail-modal-body .product-brief {
  margin-top: 50px;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 40px;
}

.sustainability {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10;
}

.sustainability p {
  font-size: 14px;
  font-weight: 300;

}

.product-detail-modal-content .close-button {
  position: absolute;
  top: 115px;
  left: 600px;
  cursor: pointer;
}

.sustainability-modal,
.product-care-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
  padding: 0;
  font-size: 13px;
  font-weight: 300;
}

.sustainability-modal.open,
.product-care-modal.open {
  max-height: 200px; /* Adjust according to content */
  padding: 1px; /* Adjust padding as needed */
}

/* Toggle icon styles */
.sustainability p img,
.product-care p img {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.sustainability p img:hover,
.product-care p img:hover {
  opacity: 0.8;
}

.call-for-enquiry-modal {
  /* position: absolute; */
  /* top: 50px;
  left: 0px; */
  width: 300px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  background-color: #fbfbfb; /* Adjust as needed */
  border: 1px solid #e1e1e1; /* Adjust as needed */
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position:absolute;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 15px;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
}

.modal-content form label {
  display: block;
  margin: 10px 0;
  text-align: left;
}

.modal-content form input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: rgb(65, 65, 65);

  
}

.modal-buttons {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.modal-buttons button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button[type="submit"] {
  background: #007bff;
  color: white;
}

.modal-buttons button[type="button"] {
  background: #ccc;
  color: black;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 300px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}


/* Mobile view styles */
@media (max-width: 768px) {
  .product-detail-page {
    flex-direction: column;
  }

  .left-section {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    margin-top: 40px;
    flex: initial;
    /* height: 60vh; */
    
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }

  .carousel {
    display: flex;
    flex-direction: row;
    scroll-snap-align: start;
    /* width: 100%; */
    /* height: 100%;  */
  }

  .carousel .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; 
    /* height: 100%;  */
    overflow: hidden;

    
  }

  .carousel .slide img {
    /* width: 100vw;
    height: 100vw; */
  }
  .carousel .slide-img {
 
    width: 100vw;
    height: 100vw;
    object-fit: contain; /* Scale image to fit inside container */
    max-height: 100%; /* Prevent overflow */
  }

  .right-section {
    width: 100%;
    padding: 20px;
    gap: 20px;
  }

  .product-detail-modal-content {
    width: 100vw;
    padding: 0px;
  }

  .product-detail-modal-body {
    padding: 40px;
  }

  .product-detail-modal-content .close-button {
    position: absolute;
    top: 60px;
    left: 300px;
    cursor: pointer;
  }

  .size-modal {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: #fff;
  }

}
