.help-container {
    padding: 100px 0;
  }
  
  .help-header {
    position: fixed;
    top: 83px;
    left: 0;
    width: 100%;
    background: white;
    padding: 0px 0px 0px 45px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgb(206, 206, 206);
  }
  
  .help-title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
  }
  
  .help-menu {
    display: flex;  
    cursor: pointer; 
  }
  
  .help-menu div {
    padding: 8px 45px;
    height: 100%;
    border-left: 1px solid rgb(206, 206, 206);
  }
  
  .help-menu p {
    text-align: center;
  }
  
  .help-content {
    margin-top: 50px;
    padding: 50px 200px;
  }
  
  .faq-question {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    padding: 10px 0;
    border-bottom: none;
  }
  
  .arrow-icon {
    margin-left: 8px;
    transition: transform 0.3s ease;
  }
  
  .arrow-icon.down {
    transform: rotate(0deg);
  }
  
  .arrow-icon.up {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    overflow: hidden;
    max-height: 0;
    margin-right: 50px;
    opacity: 0;
    transform: translateY(-10px);
    transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
  }
  
  .faq-answer.open {
    max-height: 200px; /* Adjust based on content length */
    opacity: 1;
    transform: translateY(0);
  }

  .faq-question p {
    font-weight: 400;
    font-size: 15px;
    color: rgb(69, 69, 69);
  }
  
  .faq-answer p {
    font-size: 14px;
    font-weight: 300;
    /* padding: 10px 0; */
    margin: 0;
    line-height: 25px;
    color: rgb(109, 109, 109)

  }

  .product-care p {
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
  }

  .product-care h4 {
    font-weight: 400;
    font-size: 15px;
  }

  .mobile-help-header {
    display: none;
  }

 
  

  @media (max-width: 768px) {

    .help-container {
      padding: 70px 0;
    }
    .help-header {
      display: none;
    }

    .mobile-help-header {
      display: flex;
      position: fixed;
      top: 47px;
      left: 0;
      width: 100%;
      background: white;
      padding: 20px 20px 20px 20px;
      background-color: white;
      z-index: 1000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 0.5px solid rgb(206, 206, 206);
      
    }

 

    .mobile-help-title {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      text-transform: uppercase;
    }

    .mobile-help-header .mobile-help-menu {
      position: relative;
      /* top: 50px; */
    }
  
    .mobile-help-menu-title {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 300;
      font-size: 14px;
      gap: 20px;
    }
  
    
    .arrow-icon {
      margin-left: 8px;
      transition: transform 0.3s ease;
    }
    
    .arrow-icon.down {
      transform: rotate(0deg);
    }
    
    .arrow-icon.up {
      transform: rotate(180deg);
    }
    
    .help-menu-modal {
      position: absolute;
      top: 100%;
      left: -40px;
      background: white;
      width: 150px;
      padding: 10px 0;
      z-index: 1000;
    }
    
    .help-menu-modal p {
      font-size: 13px;
      font-weight: 300;
      padding: 10px;
      margin: 0;
      cursor: pointer;
    }
    
    .help-menu-modal p:hover {
      color: #4f4f4f;
    }

    .help-content {
      margin-top: 30px;
      padding: 30px 30px;
    }
  
  }

  
  