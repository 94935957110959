.about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
    background-color: white; /* Example background color */
  }
  
  .about-content {
    display: flex;
    max-width: 1200px; /* Adjust max-width as needed */
    padding: 0 20px;
  }
  
  .about-image {
    flex: 1;
    padding: 20px;
  }
  
  .about-image img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .about-text {
    flex: 1;
    padding: 10px 40px 100px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 24px;
  }
  
  .about-text h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-family: boldeFont;
  }
  
  .about-text p {
    font-size: 14px;
    line-height: 1.6;
    color: rgb(71, 71, 71);
  }

  .about-text span {
    font-size: 0.8rem;
  }

  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
    }
    .about-text {
      padding: 10px;
    }
  }
  