.product-gallery {
    padding: 100px 0;
    /* width:100vw; */
  }
  
  .product-header {
    position: fixed;
    top: 83px;
    left: 0;
    width: 100%;
    background: white;
    padding: 33px 40px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  
  .product-header .title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
  }
  
  .sort-bar {
    position: relative;
  }
  
  .sort-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    font-size: 14px;
    gap: 20px;
  }

  
  .arrow-icon {
    margin-left: 8px;
    transition: transform 0.3s ease;
  }
  
  .arrow-icon.down {
    transform: rotate(0deg);
  }
  
  .arrow-icon.up {
    transform: rotate(180deg);
  }
  
  .sort-modal {
    position: absolute;
    top: 100%;
    left: -40px;
    background: white;
    width: 150px;
    padding: 10px 0;
    z-index: 1000;
  }
  
  .sort-modal p {
    font-size: 13px;
    font-weight: 300;
    padding: 10px;
    margin: 0;
    cursor: pointer;
  }
  
  .sort-modal p:hover {
    color: #4f4f4f;
  }
  
  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1px;
    row-gap: 30px;
    margin-top: 50px;
  }
  
  .gallery div {
    text-align: center;
    padding: 1px;
    box-sizing: border-box;
  }

  .gallery img {
    width: 100%;
    height: 470px;
    object-fit: cover;     /* Ensure the image covers the area without distortion */
    object-position: center;
  }
  
  .gallery p {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 400;
  }

  .no-product-msg {
    margin-left: 40px;
  }


  .image-slider {
    position: relative;
    width: 100%;
    height: 470px; 
    overflow: hidden;
  }
  
  .image-slider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transition: transform 0.5s ease-in-out; */
  }


  
  .slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    border-radius: 50%; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider-btn:hover {
    background-color: rgb(212, 212, 212);
  }
  
  .slider-btn.left {
    left: 10px;
    width: 40px; 
    height: 40px;
  }
  
  .slider-btn.right {
    right: 10px;
    width: 40px; 
    height: 40px; 
  }
  
  .slider-btn::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .slider-btn.left::before {
    background-image: url('../../assets/images/arrowLeft.svg'); 
  }
  
  .slider-btn.right::before {
    background-image: url('../../assets/images/arrowRight.svg'); 
  }

  @media (max-width: 768px) {
    .product-header {
      top: 46px;
      padding: 20px 15px;

    }
    
    .product-wrapper {
      margin-bottom: 50px;
    }
  
    
    .gallery {
      grid-template-columns: repeat(1, 1fr); 
      row-gap: 20px; 
    } 
  
    .gallery img {
      height: 250px;
     aspect-ratio: 1 / 1; 
    }

    .image-slider {
      height: 250px; 
    }

    .slider-btn {
     
      top: 45%;
      
      padding: 0px;
      
    }

    .gallery p {
      margin-top: 14px;
      font-size: 12px;
    }

    .no-product-msg {
      margin-left: 20px;
    }
  
  }
  