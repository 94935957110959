.landingPage {
  position: relative;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures no scrollbars due to video overflow */
  color: #fff; /* Text color to contrast with the video */
  z-index: 0; /* Default stacking context */
  overflow: hidden;
}

.landingPage h1 {
  position: relative; /* Ensures it stays above the video */
  font-family: boldeFont,sans-serif;
  font-size: 3rem;
  margin-top: 14rem; 
  margin-bottom: 0;
  z-index: 1; /* Keeps the text above the video */
  font-display: swap;
}

.landingPage p {
  position: relative; /* Ensures it stays above the video */
  font-size: 0.8rem;
  font-weight: 200;
  margin-top: 0;
  cursor: pointer;
  display: inline-block;
  z-index: 1; /* Keeps the text above the video */
  color: #fff; /* Matches the color for visibility */
}

.landingPage p::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  display: block;
  margin-top: 5px;
  background: #fff;
  transition: width 0.3s ease;
  left: 0;
}

.landingPage p:hover::after {
  width: 100%; 
}

@media (max-width: 768px) {
  .landingPage h1 {
    margin-top: 8rem; 
    text-align: center;
    line-height: 35px;
  }

  .landingPage p {
    margin-top: 20px;  
  }
}
