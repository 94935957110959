.scroll-section {
    display: flex;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
    position: relative;
  }
  
  .image {
    flex: 1;
    display: flex;
    align-items: flex-end; /* Align text to the bottom */
    justify-content: center;
    background-size: cover;
    background-position: center;
    position: relative;
    opacity: 0;
    transform: translateX(100%);
    transition: all 0.8s ease-in-out;
    padding-bottom: 17%; /* Adjust padding to position the text a little below the center */
  }
  
  .image-left {
    /* background-image: url('../../assets/images/Category1.png');  */
    transform: translateX(-100%);
  }
  
  .image-right {
    /* background-image: url('../../assets/images/Category2.png');  */
    transform: translateX(100%);
  }
  
  .scroll-section.active .image {
    opacity: 1;
    transform: translateX(0);
  }
  
  .scroll-section.active .image-left {
    transform: translateX(0);
  }
  
  .scroll-section.active .image-right {
    transform: translateX(0);
  }
  
  .text {
    color: white;
    text-align: center;
  }
  
  .text h2 {
    font-size: 2rem;
    margin: 0;
    transition: transform 0.3s ease-in-out;
    font-weight: 600;
  }
  
  .text p {
    font-size: 0.9rem;
    margin: 10px 0 0; /* Add margin-top to create space between title and subtitle */
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    position: relative;
    cursor: pointer;
    text-decoration: underline;
    
  }
  
  .text p::after {
    content: '';
    position: absolute;
    bottom: -2px; /* Adjust position to be just below the text */
    left: 0;
    width: 0; /* Start with no width */
    height: 1px;
    background-color: white;
    transition: width 0.3s ease; /* Smoothly transition the width */
  }
  
  
  .image:hover .text p {
    opacity: 1;
    transform: translateY(0);
  }
  
  .image:hover {
    flex: 1.2;
  }
  
  .image:not(:hover) {
    flex: 0.8;
  }
  
  .scroll-section.active .image:hover {
    flex: 1.15;
  }
  
  .scroll-section.active .image:not(:hover) {
    flex: 1;
  }

  @media (max-width: 768px) {
    .scroll-section {
      display: flex;
      flex-direction: column;
    }

    .image .text p {
      opacity: 1;
      transform: translateY(100%);
    }

    .image:hover {
      flex: 1;
    }
    
    .image:not(:hover) {
      flex: 1;
    }

    .scroll-section.active .image {
      opacity: 1;
      transform: translateX(0);
    }
    
    .scroll-section.active .image-left {
      transform: translateX(0);
    }
    
    .scroll-section.active .image-right {
      transform: translateX(0);
    }
    
    .scroll-section.active .image:hover {
      flex: 1;
    }
    
    .scroll-section.active .image:not(:hover) {
      flex: 1;
    }
  }
  