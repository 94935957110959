.navbar {
    position: fixed;
    top: 0;
    width: 100vw;
    background: #fff;
    border-bottom: 0.5px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 40px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    z-index: 1000;
    box-sizing: border-box; 
  }

  .navbar-left{
    display: flex;
    gap:2rem;
    cursor: pointer;
  }

  .navbar-right {
    display: flex;
    align-items: center;
    gap: 1rem
  }
  
  .navbar-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family:boldeFont,sans-serif;
    font-size: 2.5rem;
    font-weight: bold
  }

  .search-bar-toggle{
    display: flex;
    gap: 10px;
    align-items:center;
  }

.mobile-search-bar {
  display: none;
}

.mobile-search-bar-toggle {
  display: none;
}

  @media (max-width: 768px) {
    .navbar {
      padding: 10px 15px;
    }
  
    .navbar-center {
      font-size: 1.5rem;
    }

    .search-bar-toggle {
      display: none;
    }

    .mobile-search-bar-toggle {
      display: inline;
      z-index: 2000;
    }

    .mobile-search-input-wrapper {
      /* margin-top: 45px; */
      position: fixed;
      background-color: white;
      margin-bottom: 8px;
      padding: 15px 15px;
      width: 100vw;
      top: -100%;
      transition: top 0.6s ease-in-out;
      border-bottom: 1px solid rgb(208, 208, 208);
    }

    .mobile-search-input-wrapper.open {
      top:45px;
      z-index: 1001;
    }
    
    .mobile-search-input-wrapper .search-icon {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
      font-size: 1rem;
    }
  
    
    .mobile-search-input-wrapper input {
      width: 78%;
      height: 20px;
      padding: 10px 40px 10px 20px;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 50px;
    }
  
    .mobile-search-input-wrapper input:focus {
      border-color: black; 
      outline: none
    }

    .navbar-left .menu-label {
      display: none;
    }

    .navbar-left .search-label {
      display: none
    }

    .navbar-left{
      gap:1rem;
    }

  }
  